/** @format */

import { app } from './app.js';

/** @format */

class Common {
	constructor() {
		this.name = 'common';
		this.init();
	}
	init = () => {
		app.fn.hooks.register('image.show', (url) => {
			app.state.current_image = url;
			app.ui.image.style.backgroundImage = `url(${process.env.MM_MEDIA}/ui_renders/${url}.png)`;
			app.ui.image.classList.add('has-image');

			if (app.ui.download) {
				app.ui.download.href = `${process.env.MM_MEDIA}/ui_renders/${url}.png`;

				app.ui.image.classList.remove('loading');
				// this.disable_enable_buttons(1);

				// this.clear_votes();
				// app.ui.vote_area.classList.add('visible');

				// pannellum.viewer('panorama', {
				// 	type: 'equirectangular',
				// 	panorama: url,
				// 	autoLoad: true,
				// 	showZoomCtrl: false,
				// 	showFullscreenCtrl: false,
				// 	autoRotate: true,
				// 	pfov: 90,
				// });
			}
		});
	};
}

module.exports = Common;
