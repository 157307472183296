/** @format */

import { app } from './app.js';

class Fetch {
	constructor() {
		this.init();
	}

	init = () => {
		app.fn.fetch = this.prompt;
	};

	prompt = (props = {}) => {
		let payload = {
			...props.data,
			steps: 20,
			// negative_prompt:
			// 	'(Neg_Facelift768:0.3), (blur:0.3), (cropped:1.3), (ugly:1.3), (bad anatomy:1.2), (disfigured:1.1), (deformed:1.1), (bad proportions:1.3), (extra limbs:1.2), (missing fingers:1.2), (extra fingers:1.2), (out of frame:1.3), (makeup:1.1)',
		};

		// if payload.prompt starts with <lora: then change restore_faces to false
		if (payload.prompt.startsWith('<lora:')) {
			// payload.restore_faces = false;
		}

		if (app.state.mode === 'location') {
			payload.prompt = `${payload.prompt}. <lora:360Diffusion_v1:1>`;
			payload.h = 512;
			payload.w = 1024;
		}

		if (app.ui.splash.dataset.aspect === 'landscape') {
			payload.w = 912;
			payload.h = 512;
		} else {
			payload.w = 512;
			payload.h = 512;
		}

		payload.model = app.ui.splash.dataset.model ?? 'bfs';
		payload.steps = 40;
		// payload.scheduler = 'DDPMScheduler';
		// payload.enhance_prompt = 'yes';
		// payload.tomesd = 'yes';
		// payload.use_karras_sigmas = 'yes';
		// payload.multi_lingual = 'yes';
		// payload.self_attention = 'yes';
		// payload.upscale = 'yes';

		let endpoint = process.env.MM_ENDPOINT + '/uirender';
		// endpoint += '/' + props.action == 'prompt.do' ? 'txt2img' : 'img2img';

		payload.type = props.action == 'prompt.do' ? 'txt2img' : 'img2img';

		let headers = {
			accept: '*/*',
		};

		if (app.state.model === 'base') {
			// payload.num_inference_steps = payload.steps;
			// delete payload.steps;
			// payload.scheduler = 'EULER-A';
			// delete payload.sampler_index;
			// delete payload.hr_scale;
			// delete payload.restore_faces;
			// endpoint = process.env.RENDER_ENDPOINT;
			// headers.authorization = 'Bearer ' + process.env.RENDER_TOKEN;
			// headers['content-type'] = 'application/json';
			// payload = { input: payload };
		}
		// console.log(payload);
		// exit;

		this.make_request(endpoint, payload, headers).then((json) => {
			// if (app.state.model === 'base') {
			// 	app.fn.hooks.call('image.show', json.output[0].image);

			// 	this.make_request(process.env.LIBRARIAN_ENDPOINT, {
			// 		images: [json.output[0].image],
			// 	});
			// } else {
			if (json.error) {
				console.log(json.error);
				this.prompt(props);
				return;
			}

			app.fn.hooks.call('image.show', json.img);

			// this.make_request(process.env.LIBRARIAN_ENDPOINT, {
			// 	images: [json],
			// });
			// }
			app.ui.image.classList.remove('loading');
			// this.image.style.backgroundImage = `url(${json[0]})`;
			// this.download.href = json[0];
			// this.image.classList.remove("loading");
		});
	};

	make_request = (endpoint, payload, headers = null) => {
		return fetch(endpoint, {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
				...headers,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.then((json) => json);
	};
}

module.exports = Fetch;
