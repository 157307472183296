/** @format */

import { app } from './app.js';
// import { fabric } from 'fabric';
import Voice from './voice.js';
// include fs
// const fs = require('fs');

// import script_demo.json
import script_demo from '../script_demo.json';

class Board {
	constructor() {
		this.history = [];
		this.historyIndex = -1;
		this.init();
	}

	init = () => {
		app.state.image_list = {};
		app.fn.board = {};
		app.fn.board.run = this.run;
		app.ui.board = {};
		app.ui.board.editor = document.getElementById('board_editor');

		app.ui.board.canvas =
			app.ui.board.editor.querySelector('.panel_canvas');
		app.ui.board.script = app.ui.board.editor.querySelector('.script');
		app.ui.board.settings = app.ui.board.editor.querySelector('.settings');

		this.voice = new Voice();

		// add event listeners to the buttons
		app.ui.board.editor
			.querySelector('#render_me')
			.addEventListener('click', this.render_me);

		// get the first scene from the script_demo.json file
		app.state.scene = script_demo.scenes[0];
		// get the first shot from the first scene
		app.state.shot = app.state.scene.shots[0];
		// console.log(app.state.shot);

		// console.log(app.ui.board);

		this.prep_canvas();

		this.show_script();
	};

	prep_canvas = () => {
		// add canvas to the canvas element
		app.ui.board.canvas.innerHTML = `<canvas></canvas>`;
		const canvas = app.ui.board.canvas.querySelector('canvas');
		canvas.id = 'canvas-123';
		this.canvas = new ImageEditor(canvas.id);
		canvas.classList.add('canvas');

		// this.voice.getAudio(
		// 	'Well hello there, friend. How are you this evening?',
		// 	'b0UOQLDPxUJopOLYgwPP'
		// );

		this.load_characters();
	};

	load_characters = () => {
		// get the characters from the shot
		const characters = app.state.shot.characters;

		// for each character
		characters.forEach((character, index) => {
			this.load_character(character);
		});
	};

	load_character = (character_data) => {
		// get default time value
		const this_state = character_data.time[0];
		const canvas = app.ui.board.canvas.querySelector('canvas');

		this.canvas.addImage(character_data);
	};

	show_script = () => {
		// get the script from this shot
		let script = app.state.shot.script;

		// for each line in the script
		script.forEach((line, index) => {
			// if the type is 'dialogue'
			if (line.type === 'dialogue') {
				// create a new div element
				let div = document.createElement('div');
				div.classList.add('line');
				// set the innerHTML of the div to the line
				div.innerHTML = line.text;
				div.dataset.character = line.character;
				// append the div to the #script element
				app.ui.board.script.appendChild(div);
			} else {
				// create a new div element
				let div = document.createElement('div');
				div.classList.add('line');
				// set the innerHTML of the div to the line
				div.innerHTML = line.text;

				// append the div to the #script element
				app.ui.board.script.appendChild(div);
			}
		});
	};

	render_me = () => {
		// get the image data from the canvas element and display it in the #render element
		const canvas = app.ui.board.canvas.querySelector('canvas');
		const dataInfo = canvas.toDataURL('image/png');

		app.ui.image = document.getElementById('render');

		app.state.current_image = dataInfo;

		app.fn.prompt.run({
			prompt: 'bfs2023-15 style, ' + app.state.shot.script[0].text,
			revise: true,
		});
	};
}

class ImageEditor {
	constructor(canvasId) {
		// get the width and height of the canvas's parent element
		this.canvasWidth =
			document.getElementById(canvasId).parentElement.offsetWidth;
		this.canvasHeight =
			document.getElementById(canvasId).parentElement.offsetHeight;

		this.canvas = new fabric.Canvas(canvasId, {
			preserveObjectStacking: true,
			selection: false,
			width: this.canvasWidth,
			height: this.canvasHeight,
			backgroundColor: '#fff',
		});
		this.history = [];
		this.historyIndex = -1;
		this.initEvents();
	}

	addImage(data) {
		// get the url of the image
		console.log(data);
		let url = `characters/${data.id}.png`;
		fabric.Image.fromURL(url, (img) => {
			// get the width and height of the image relative to the canvas
			let w = data.time[0].w * this.canvasWidth;
			let h = data.time[0].h * this.canvasHeight;

			img.scaleToWidth(w);
			img.scaleToHeight(h);

			// get the x and y coordinates relative to the canvas
			let x = data.time[0].x * this.canvasWidth;
			let y = data.time[0].y * this.canvasHeight;

			// add the image onto canvas
			img.set({ left: x, top: y, id: data.id });
			this.canvas.add(img);
			this.saveState();
		});
	}

	initEvents() {
		this.canvas.on('object:modified', () => {
			this.saveState();
		});

		document.addEventListener('keydown', (e) => {
			// if the user presses ctrl+z or cmd+z, call the undo method
			if ((e.ctrlKey || e.metaKey) && e.key === 'z') {
				e.preventDefault();
				this.undo();
			} else if ((e.ctrlKey || e.metaKey) && e.key === 'y') {
				e.preventDefault();
				this.redo();
			}
		});

		// when the user clicks on the canvas, get the ID of the object that was clicked
		this.canvas.on('mouse:down', (e) => {
			if (e.target) {
				console.log(e.target.id);
			}
		});

		// when the user stops dragging an object, get the ID of the object that was clicked
		this.canvas.on('mouse:up', (e) => {
			if (e.target) {
				console.log(e.target.id);
				// get the details of the object that was clicked
				const details = this.getElementDetails(e.target.id);
				// find the character with the same ID as the object that was clicked
				const character = app.state.shot.characters.find(
					(character) => character.id === e.target.id
				);
				let character_details = character.time[0];
				// update the character's details with the new details
				character_details.x = details.x;
				character_details.y = details.y;
				character_details.w = details.w;
				character_details.h = details.h;
				character_details.r = details.r;
				// update the shot's characters with the new character
				app.state.shot.characters = app.state.shot.characters.map(
					(character) => {
						if (character.id === e.target.id) {
							return character;
						} else {
							return character;
						}
					}
				);
				this.saveData(script_demo);
			}
		});
	}

	saveData = async () => {};

	calcuateRelatives = (element) => {
		const { left, top, width, height } = element;
		// get the actual width of the element
		let actualWidth = width * element.scaleX;
		// get the actual height of the element
		let actualHeight = height * element.scaleY;

		const { width: canvasWidth, height: canvasHeight } = this.canvas;
		const relativeLeft = left / canvasWidth;
		const relativeTop = top / canvasHeight;
		const relativeWidth = actualWidth / canvasWidth;
		const relativeHeight = actualHeight / canvasHeight;
		return { relativeLeft, relativeTop, relativeWidth, relativeHeight };
	};

	getElementDetails = (id) => {
		const element = this.getById(id);

		const { relativeLeft, relativeTop, relativeWidth, relativeHeight } =
			this.calcuateRelatives(element);

		return {
			id: element.id,
			x: relativeLeft,
			y: relativeTop,
			w: relativeWidth,
			h: relativeHeight,
			r: element.angle,
		};
	};

	saveState() {
		this.history = this.history.slice(0, this.historyIndex + 1);
		this.history.push(JSON.stringify(this.canvas));
		this.historyIndex++;
	}

	undo() {
		if (this.historyIndex > 0) {
			this.historyIndex--;
			this.canvas.loadFromJSON(this.history[this.historyIndex]);
			this.canvas.renderAll();
		}
	}

	redo() {
		if (this.historyIndex < this.history.length - 1) {
			this.historyIndex++;
			this.canvas.loadFromJSON(this.history[this.historyIndex]);
			this.canvas.renderAll();
		}
	}

	getById(id) {
		return this.canvas.getObjects().find((obj) => obj.id === id);
	}
}

module.exports = { Board, ImageEditor };
