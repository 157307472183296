import { app } from './app.js';

class Hooks {

	constructor() {

		app.fn.hooks = {};
		app.fn.hooks.register = this.register;
		app.fn.hooks.call = this.call;

	}

	register(name, fn) {
		console.log('registering', name);
		if (!app.hooks[name]) {
			app.hooks[name] = [];
		}
		app.hooks[name].push(fn);
	}

	call(name, ...args) {
		console.log('calling', name);
		if (!app.hooks[name]) {
			return;
		}
		app.hooks[name].forEach((fn) => {
			if (typeof fn === 'function') {
				fn(...args);
			}
		});
	}

}

module.exports = Hooks;