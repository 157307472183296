{
	"fps": 24,
	"aspect": "16/9",
	"scenes": [
		{
			"id": "001",
			"shots": [
				{
					"id": "A",
					"duration": 500,
					"script": [
						{
							"type": "description",
							"text": "Alois is sitting on a bench in the park. He is eating an apple."
						},
						{
							"type": "dialogue",
							"character": "Alois",
							"text": "I'm not sure if I should be happy or sad that you're here."
						}
					],
					"characters": [
						{
							"id": "alois",
							"time": [
								{
									"tc": 0,
									"x": 0.521351217538947,
									"y": -0.027842227378190254,
									"w": 0.6316596537048711,
									"h": 1.1203133007768753,
									"r": 0,
									"face": "right",
									"emotion": "neutral",
									"audio": "b0UOQLDPxUJopOLYgwPP"
								},
								{
									"tc": 500,
									"x": 50,
									"y": 0,
									"z": 0,
									"scale": 1
								}
							]
						}
					]
				}
			]
		}
	]
}
