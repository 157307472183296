import { app } from './app.js';

class Ws {

	constructor() {
		this.socket;
		this.socket_state = false;
		this.init();

		app.fn.ws = {}
		app.fn.ws.send = this.ws_send;
		app.fn.ws.process = this.ws_process;

		app.meta = {};

	}

	init = async () => {
		this.websocket_connect();
	}

	reconnect = async () => {
		this.websocket_connect();
	}

	websocket_connect = async () => {
		console.info(`Connecting to ${process.env.CONCIERGE_ENDPOINT}`);
		this.socket = new WebSocket(`wss://${process.env.CONCIERGE_ENDPOINT}`);

		this.socket.onopen = (e) => {

			// alert("[open] Connection established");
			// alert("Sending to server");
			this.socket_state = true;

			app.fn.hooks.call('ws.connect')

		};

		this.socket.onmessage = (event) => {
			let out = JSON.parse(event.data);
			console.log(out);
			this.ws_process(out);
			// alert(`[message] Data received from server: ${event.data}`);
		};

		this.socket.onclose = (event) => {
			this.socket_state = false;
			if (event.wasClean) {
				console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
			} else {
				// e.g. server process killed or network down
				// event.code is usually 1006 in this case
				console.log('[close] Connection died');
				this.reconnect();
			}
			// reconnect
			// this.reconnect();
		};

		this.socket.onerror = (error) => {
			this.socket_state = false;
			// alert(`[error]`);
		};

		// console.log('socket connected', this.socket)

		return true;
	}

	ws_process = async (out) => {
		console.log('ws received', out);
		if (out.update) {
			out.type = 'ui';
			out.action = 'update';
			out.result = out.update;
		}
		app.fn.hooks.call(`${out.type}.${out.action}`, out.result);

	}

	ws_send = async (data, callback) => {
		console.log('sending', data)
		await this.socket.send(JSON.stringify(data));
	}

	save_meta = async (data) => {
		// console.log('got meta', data)
		// data.result.forEach((item) => {
		app.meta[data.action] = data.result;
		// });
	}

}

module.exports = Ws;