/** @format */

import { app } from './js/app.js';
import Fetch from './js/fetch.js';
import Hooks from './js/hooks.js';
import Ui from './js/ui.js';
import Ws from './js/ws.js';
import Prompt from './js/prompt.js';
import { Board, ImageEditor } from './js/board.js';
import common from './js/common.js';

// import the views/home.html file
import home from 'bundle-text:./views/home.html';
import board from 'bundle-text:./views/board.html';

// get the query string value for view
const urlParams = new URLSearchParams(window.location.search);

// set the app.state.view to the query string value
app.state.view = urlParams.get('view') ?? 'home';

new Hooks();
new common();
new Fetch();
new Prompt();

if (app.state.view === 'home') {
	// set the innerHTML of the #app element to the home view
	document.getElementById('app').innerHTML = home;

	new Ui();
} else if (app.state.view === 'board') {
	document.getElementById('app').innerHTML = board;
	new Ui();
	new Board();
}
