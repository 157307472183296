/** @format */

class Voice {
	constructor() {
		this.api_key = '5f3f1392563b3e13da76de6895da26dc';
		this.endpoint =
			'https://api.elevenlabs.io/v1/text-to-speech/{voice_id}';
		this.voice_id = 'b0UOQLDPxUJopOLYgwPP';
	}

	// get audio from the api
	getAudio = (text, voice_id) => {
		const url = this.endpoint.replace('{voice_id}', voice_id);
		const data = {
			text: text,
			voice_settings: {
				stability: 0,
				similarity_boost: 0,
			},
		};

		return fetch(url, {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
				'xi-api-key': this.api_key,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				return data;
			});
	};

	// add audio to the page
	addAudio = (audio) => {
		// create a new audio element
		let audioElement = document.createElement('audio');
		// set the source of the audio element to the audio url
		audioElement.src = audio.url;
		// append the audio element to the #audio element
		document.querySelector('#audio').appendChild(audioElement);
	};
}

module.exports = Voice;
