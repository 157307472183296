/** @format */

import { app } from './app.js';
import versions from '../versions.json';

class Ui {
	constructor() {
		app.ui.form = document.getElementById('prompt-form');
		app.ui.image = document.getElementById('image');
		app.ui.download = document.querySelector('.download');
		app.ui.votes = document.querySelectorAll('.vote');
		app.ui.vote_area = document.querySelector('.vote-space');
		app.ui.splash = document.querySelector('.splash');

		this.init();
	}

	run_prompt = (props = {}) => {
		app.ui.image.classList.add('loading');
		// app.ui.vote_area.classList.remove('visible');
		this.disable_enable_buttons(0);
		app.fn.prompt.run(props);
	};

	init = () => {
		if (app.state.view === 'home') {
			app.ui.form.addEventListener('submit', (e) => {
				e.preventDefault();
				this.run_prompt();
			});

			// get the .submit button
			app.ui.submit = document.querySelector('.submit');
			app.ui.submit.addEventListener('click', (e) => {
				this.run_prompt();
			});

			// get the .revise button
			app.ui.revise = document.querySelector('.retry');
			app.ui.revise.addEventListener('click', (e) => {
				e.preventDefault();
				this.run_prompt({ revise: true });
			});

			// get the .aspect button
			// app.ui.aspect = document.querySelector('.aspect');
			// app.ui.aspect.addEventListener('click', (e) => {
			// 	app.ui.splash.dataset.aspect =
			// 		app.ui.splash.dataset.aspect == 'square'
			// 			? 'landscape'
			// 			: 'square';
			// });

			//get the .model button
			app.ui.model = document.querySelector('.model');
			app.ui.model.addEventListener('click', (e) => {
				e.target.classList.toggle('open');
			});

			// get all the .item elements in .model-choices
			app.ui.model_choices = document.querySelectorAll(
				'.model-choices .item'
			);
			app.ui.model_choices.forEach((el) => {
				el.addEventListener('click', (e) => {
					const model = e.target.dataset.model;
					app.ui.splash.dataset.model = model;
					// remove 'active' from all .item elements
					app.ui.model_choices.forEach((el) => {
						el.classList.remove('active');
					});
					// add 'active' to the clicked .item element
					e.target.classList.add('active');

					// remove .open from .model
					app.ui.model.classList.remove('open');
				});
			});
		}

		// get the .vote buttons
		app.ui.votes.forEach((el) => {
			el.addEventListener('click', (e) => {
				e.preventDefault();

				this.clear_votes();

				e.target.classList.add('on');
			});
		});

		this.replace_versions();

		// detect .mode clicks
		const modes = document.querySelectorAll('.mode');
		modes.forEach((el) => {
			el.addEventListener('click', (e) => {
				e.preventDefault();

				modes.forEach((el) => {
					el.classList.remove('active');
				});

				e.target.classList.add('active');

				// get the data-mode
				const mode = e.target.dataset.mode;

				this.switch_mode(mode);
			});
		});
	};

	switch_mode = (mode) => {
		// add the mode to the .splash element
		app.ui.splash.dataset.mode = mode;
		app.state.mode = mode;
	};

	disable_enable_buttons = (state) => {
		if (state == 1) {
			app.ui.form.classList.add('active');
		} else {
			app.ui.form.classList.remove('active');
		}
	};

	clear_votes = () => {
		app.ui.votes.forEach((el) => {
			el.classList.remove('on');
		});

		// select the .vote that is data-vote="0"
		app.ui.vote = document.querySelector("[data-vote='0']");
		app.ui.vote.classList.add('on');
	};

	replace_versions = () => {
		let version = document.querySelectorAll('[data-version]');
		version.forEach((el) => {
			if (versions[el.dataset.version]) {
				el.innerHTML = versions[el.dataset.version];
			}
		});
	};
}

module.exports = Ui;
