/** @format */

import { app } from './app.js';

class Prompt {
	constructor() {
		this.init();
	}

	init = () => {
		app.fn.prompt = {};
		app.fn.prompt.run = this.run;
	};

	run = (props = {}) => {
		let prompt = '';
		if (props.prompt) {
			prompt = props.prompt;
		} else {
			prompt = document.getElementById('prompt').value;
		}

		let prompt_type = 'do';

		let payload = {
			prompt: `${prompt}`,
			width: 768,
			height: 768,
		};

		let model = 'base';
		// if there is a 'choose_model' dropdown
		// if (document.getElementById('choose_model')) {
		// 	// get the value of the #choose_model dropdown
		// 	model = document.getElementById('choose_model').value;
		// }

		// // if the model is not 'base', modify the prompt
		// if (model === 'bfs') {
		// 	payload.prompt = `bfs2023_101 style, ${prompt}`;
		// }

		app.state.model = model;

		if (props.revise) {
			// load image data from url
			// const img = new Image();
			// img.src = app.state.current_image;
			// img.crossOrigin = 'anonymous';

			// img.onload = () => {
			// 	const canvas = document.createElement('canvas');
			// 	canvas.width = img.width;
			// 	canvas.height = img.height;

			// 	const ctx = canvas.getContext('2d');
			// 	ctx.drawImage(img, 0, 0);

			// 	const dataInfo = canvas.toDataURL('image/png');

			// 	payload.init_images = [dataInfo];
			// 	payload.denoising = 0.65;
			// 	payload.cfg_scale = 7.5;
			// 	prompt_type = 'revise';
			// 	payload.width = img.width;
			// 	payload.height = img.height;

			// 	// console.log(dataInfo);

			// 	app.fn.fetch({
			// 		action: 'prompt.' + prompt_type,
			// 		data: payload,
			// 	});
			// 	// app.fn.ws.send({ action: 'prompt.' + prompt_type, data: payload });
			// };

			// get the current image url from the app.ui.download.href
			let current_im = [app.ui.download.href];
			payload.init_images = current_im;

			app.fn.fetch({
				action: 'prompt.' + prompt_type,
				data: payload,
			});
		} else {
			app.fn.fetch({ action: 'prompt.' + prompt_type, data: payload });
			// app.fn.ws.send({ action: 'prompt.' + prompt_type, data: payload });
		}
	};
}

module.exports = Prompt;
